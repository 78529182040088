import './styles.css'

// const importRoutes = async (name) => {
//   const sub_routes = await import('./config/routes')
//   return ({ default: sub_routes.default[name] })
// }

export default function init({ onAuthed, vars, sparkyFramework, loadCss }) {
  // let cie_feature_flags = {}
  // const cieNav = {
  //   key: 'cie',
  //   path: '/cie',
  //   contentClassName: 'panwds-tw3',
  //   noRBACheck: true,
  //   expandable: true,
  //   hidden: true,
  //   isLoading: () => isLoading,
  //   // avail: ({ instances }) => instances?.isRunningStatus('directory_sync'),
  //   redirectTo: '/cie/setup',
  //   children: [{
  //     key: 'cie-setup',
  //     path: '/cie/setup',
  //     noRBACheck: true,
  //     hidden: true
  //   }],
  //   component: async () => (await importRoutes('CIERoutes')), //import('./config/routes'), // // no component needed in children
  //   mapStateToProps: ({ auth, cie_feature_flags }) => ({ tsg_id: auth?.tsg_id, vars, cie_feature_flags, tsg_name: auth.tsgs.get(auth.tsg_id)?.display_name }),
  // }
  // const cieNav = {
  //   key: 'cie',
  //   path: '/cie',
  //   contentClassName: 'panwds-tw3',
  //   noRBACheck: true,
  //   expandable: true,
  //   hidden: true,
  //   isLoading: () => isLoading,
  //   // avail: ({ instances }) => instances?.isRunningStatus('directory_sync'),
  //   redirectTo: '/cie/setup',
  //   children: [{
  //     key: 'cie-setup',
  //     path: '/cie/setup',
  //     noRBACheck: true,
  //     hidden: true
  //   }],
  //   component: async () => (await importRoutes('CIERoutes')), //import('./config/routes'), // // no component needed in children
  //   mapStateToProps: ({ auth, cie_feature_flags }) => ({ tsg_id: auth?.tsg_id, vars, cie_feature_flags, tsg_name: auth.tsgs.get(auth.tsg_id)?.display_name }),
  // }
  // const dssNav = {
  //   key: 'dirsync',
  //   title: 'Directory Sync',
  //   icon: DirectoryIcon,
  //   contentClassName: 'panwds-tw3',
  //   path: '/cie/dirsync',
  //   noRBACheck: true,
  //   expandable: true,
  //   // isLoading: () => isLoading,
  //   // avail: ({ instances }) => instances?.isRunningStatus('directory_sync') && !Boolean(cie_feature_flags?.tsg_alias),
  //   redirectTo: '/cie/dirsync/directory',
  //   children: [
  //     {
  //       key: 'dirsync-directory',
  //       title: 'Directories',
  //       path: '/cie/dirsync/directory',
  //       noRBACheck: true
  //     },
  //     {
  //       key: 'dirsync-agent',
  //       title: 'Agents & Certificates',
  //       path: '/cie/dirsync/agent',
  //       noRBACheck: true
  //     }, {
  //       key: 'dirsync-attribute',
  //       title: 'Attributes',
  //       path: '/cie/dirsync/attribute',
  //       noRBACheck: true
  //     }, {
  //       key: 'dirsync-setup',
  //       path: '/cie/dirsync/setup',
  //       noRBACheck: true,
  //       hidden: true
  //     }, {
  //       key: 'dirsync-setup-directory',
  //       path: '/cie/dirsync/setup-directory',
  //       noRBACheck: true,
  //       hidden: true
  //     }, {
  //       key: 'dirsync-setup-onprem',
  //       path: '/cie/dirsync/setup-onprem',
  //       noRBACheck: true,
  //       hidden: true
  //     }, {
  //       key: 'dirsync-authorize',
  //       path: '/cie/dirsync/authorize',
  //       noRBACheck: true,
  //       hidden: true
  //     }, {
  //       key: 'dirsync-directory-detail',
  //       path: '/cie/dirsync/directory-detail',
  //       noRBACheck: true,
  //       hidden: true
  //     }, {
  //       key: 'dirsync-setup-group',
  //       path: '/cie/dirsync/setup-group',
  //       noRBACheck: true,
  //       hidden: true
  //     }
  //   ],
  //   component: async () => (await importRoutes('DSSRoutes')), //import('./config/routes'), // // no component needed in children
  //   mapStateToProps: ({ auth, cie_feature_flags, theme }) => ({
  //     tsg_id: auth?.tsg_id,
  //     vars,
  //     cie_feature_flags,
  //     tenantId: auth?.instances?.get('directory_sync')?.tenant_id,
  //     instanceName: auth.tsgs.get(auth.tsg_id)?.display_name,
  //     theme
  //   }),
  // }
  // const casNav = {
  //   key: 'cas',
  //   title: 'Authentication',
  //   icon: AuthIcon,
  //   contentClassName: 'panwds-tw3',
  //   path: '/cie/cas',
  //   noRBACheck: true,
  //   expandable: true,
  //   isLoading: () => isLoading,
  //   // avail: ({ instances }) => Boolean(instances?.isRunningStatus('directory_sync')) && !Boolean(cie_feature_flags?.tsg_alias),
  //   children: [
  //     {
  //       key: 'cas-ca-chains',
  //       title: 'CA Chains',
  //       path: '/cie/cas/ca-chains',
  //       noRBACheck: true
  //     },
  //     {
  //       key: 'cas-auth-types',
  //       title: 'Authentication Types',
  //       path: '/cie/cas/auth-types',
  //       noRBACheck: true
  //     },
  //     {
  //       key: 'cas-auth-profiles',
  //       title: 'Authentication Profiles',
  //       path: '/cie/cas/auth-profiles',
  //       noRBACheck: true
  //     },
  //     {
  //       key: 'cas-sp-metadata',
  //       title: 'SP Metadata',
  //       path: '/cie/cas/sp-metadata',
  //       noRBACheck: true
  //     },
  //     {
  //       key: 'cas-log-viewer',
  //       title: 'Log Viewer',
  //       path: '/cie/cas/log-viewer',
  //       noRBACheck: true
  //     },
  //     {
  //       key: 'cas-setup-saml',
  //       path: '/cie/cas/setup-saml',
  //       noRBACheck: true,
  //       hidden: true
  //     },
  //     {
  //       key: 'cas-setup-cert',
  //       path: '/cie/cas/setup-cert',
  //       noRBACheck: true,
  //       hidden: true
  //     },
  //     {
  //       key: 'cas-setup-auth-type',
  //       path: '/cie/cas/setup-auth-type',
  //       noRBACheck: true,
  //       hidden: true
  //     },
  //     {
  //       key: 'cas-setup-auth-profile',
  //       path: '/cie/cas/setup-auth-profile',
  //       noRBACheck: true,
  //       hidden: true
  //     },
  //   ],
  //   component: async () => (await importRoutes('CASRoutes')), //import('./config/routes'), //,//no component needed in children
  //   mapStateToProps: ({ auth, cie_feature_flags, theme }) => ({
  //     tsg_id: auth?.tsg_id,
  //     vars,
  //     cie_feature_flags,
  //     tenantId: auth?.instances?.get('directory_sync')?.tenant_id,
  //     instanceName: auth.tsgs.get(auth.tsg_id)?.display_name,
  //     theme
  //   }),
  // }
  // const uisNav = {
  //   key: 'uis',
  //   title: 'User Context',
  //   icon: ChannelsIcon,
  //   contentClassName: 'panwds-tw3',
  //   path: '/cie/uis',
  //   noRBACheck: true,
  //   expandable: true,
  //   isLoading: () => isLoading,
  //   avail: ({ instances }) => Boolean(instances?.isRunningStatus('directory_sync')) && Boolean(cie_feature_flags?.uis),
  //   children: [
  //     {
  //       key: 'uis-mapping',
  //       title: 'Mappings & Tags',
  //       path: '/cie/uis/mappings',
  //       noRBACheck: true
  //     }, {
  //       key: 'uis-segment',
  //       title: 'Segments',
  //       path: '/cie/uis/segments',
  //       noRBACheck: true
  //     },
  //     {
  //       key: 'uis-third-party-device',
  //       title: 'Third-Party Device-ID',
  //       path: '/cie/uis/third-party-device',
  //       noRBACheck: true,
  //       callAvail: true,
  //       isLoading: () => isLoading,
  //       avail: ({ instances }) => Boolean(instances?.isRunningStatus('directory_sync')) && Boolean(cie_feature_flags?.third_party_device),
  //     },
  //     {
  //       key: 'uis-setup-third-party-device',
  //       title: 'Setup Third-Party Device-ID',
  //       path: '/cie/uis/setup-third-party-device',
  //       noRBACheck: true,
  //       hidden: true,
  //       callAvail: true,
  //       isLoading: () => isLoading,
  //       avail: ({ instances }) => Boolean(instances?.isRunningStatus('directory_sync')) && Boolean(cie_feature_flags?.third_party_device)
  //     },
  //     {
  //       key: 'uis-segment-view',
  //       path: '/cie/uis/segment-view',
  //       noRBACheck: true,
  //       hidden: true
  //     }, {
  //       key: 'uis-firewall-view',
  //       path: '/cie/uis/firewall-view',
  //       noRBACheck: true,
  //       hidden: true
  //     }, {
  //       key: 'uis-activate',
  //       path: '/cie/uis/activate',
  //       noRBACheck: true,
  //       hidden: true
  //     }],
  //   component: async () => (await importRoutes('UISRoutes')), //import('./config/routes'), //,//no component needed in children
  //   mapStateToProps: ({ auth, cie_feature_flags, theme }) => ({
  //     tsg_id: auth?.tsg_id,
  //     vars,
  //     cie_feature_flags,
  //     tenantId: auth?.instances?.get('directory_sync')?.tenant_id,
  //     instanceName: auth.tsgs.get(auth.tsg_id)?.display_name,
  //     theme
  //   }),
  // }
  onAuthed(async ({ auth }) => {
    // console.log('onAuthed', auth?.instances)
    if (auth?.instances?.isRunningStatus('directory_sync')) {
      // const { getFeatureflags } = await import('./services/UtilService')
      // const { data } = await getFeatureflags()
      // cie_feature_flags = data
      // sparkyFramework.actions.setValues({ cie_feature_flags })
      sparkyFramework.events.on('switch-tsg-loaded', (auth_update) => {
        auth_update.forceReload = true
      })
    }
    // sparkyFramework.events.on('switch-tsg-completed', async () => {
    //   const { data } = await getFeatureflags()
    //   cie_feature_flags = data
    //   isLoading = false
    //   sparkyFramework.actions.setValues({ cie_feature_flags })
    // })
    //list tsgid event
    // switch-tsg-completed
  })

  return function tsgLoad() {
    return {
      navigation: [
        {
          title: 'CIE Onboard Dev',
          key: 'cie_onboarding_dev',
          path: '/cie_onboarding_dev',
          hidden: true,
          contentClassName: 'panwds-tw3 panwds-unset-reboot sparky-dark-only',
          component: () => import('./components/BlueBird/CIEOnboardingDev')
        }
      ]
    }
  }

};

export const exposes = {
  CIEComponents: {
    ProfileSelect: () => import('./components/BlueBird/CIEProfileSelect'),
  }
}
